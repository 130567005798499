import {
  Button,
  InputGroup,
  Form,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerAuth } from "../../store/slices/ownerSlice";
import { folderService } from "../../_services/folder";
import { videoService } from "../../_services/video";
import { toast } from "react-hot-toast";
 import { useFormik } from "formik";
import { mediaServices } from "../../_services/media";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as Yup from "yup";
import {
  emptyRecordedData,
  setRecordVdoStep1Data,
} from "../../store/slices/recordingFormSlice";
import Loader from "../Loader";
import moment from "moment";
import getBlobDuration from "get-blob-duration";
import Swal from "sweetalert2";
import { useAuth } from "../../_hooks/useAuth";
import { planServices } from "../../_services";
// import AWS from "aws-sdk";
import AWS from "aws-sdk";
import CryptoJS from 'crypto-js';

const FolderModal = ({
  handleClose,
  setShowRecordingModel,
  user,
  subscriptionCurrentPlanData,
}) => {
  const role = useAuth("role");
  const loggedInOwnerData = useSelector(getOwnerAuth);
  const [folderList, setFolderList] = useState();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderSelected, setFolderSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [base64String, setBase64String] = useState("");
  const [isLoadingFolderList, setIsLoadingFolderList] = useState(false);
  const [isLoadingVdoUpload, setIsLoadingVdoUpload] = useState(false);
  const [uploadController, setUploadController] = useState(null);
  const [manageRecordVdoValidation, setManageRecordVdoValidation] =
    useState(false);
  const [testView, setTestView] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [scheduleSharingError, setScheduleSharingError] = useState();
  const [videoLength, setVideoLength] = useState();
  const [manageS3Loader, setManageS3Loader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dailyRateData, setdailyRateData] = useState({
    totalTodayUsedPrice: "0", // Default to "0" to avoid empty values
    totalTodayUsedData: "0",
    dataRemaining: "0",
    discountPrice: "0",
    lastPayment: "0",
    noOfVideos: "0",
    noOfImages: "0",
    ownerName: "0",
    noOfAudio: "0",
    imageSize: "0",
    videoSize: "0",
    audioSize: "0",
    dataused: "0",
    docSize: "0",
    noOfDoc: "0",
    ownerId: "0",
    discountPrice: "0",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  // FETCHING THE OWNER ID

  const decrypt = (encryptedData, secretKey) => {
    // Check if encryptedData is in word array format (array of numbers)
    if (Array.isArray(encryptedData)) {
      // Convert the word array into a Hex string
      encryptedData = CryptoJS.enc.Hex.stringify(CryptoJS.enc.WordArray.create(encryptedData));
    }
  
    // Decrypt the Hex or Base64 encoded data
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  
    // Convert the decrypted bytes back into a UTF-8 string
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  
    // Handle potential decryption errors (e.g., when data is not a valid UTF-8 string)
    if (!decryptedData) {
      throw new Error('Decryption failed or result is not valid UTF-8');
    }
  
    return decryptedData;
  };
  
  useEffect(() => {
    if (loggedInOwnerData?._id) {
      fetchData(loggedInOwnerData?._id);
    }
    getDailyRate(loggedInOwnerData?._id);
  }, [loggedInOwnerData]);

  const getDailyRate = async (ownerId) => {
    let todayDate = moment().format("DD/MM/YYYY");
    folderService.getDailyRateData(ownerId, todayDate).then((response) => {
      if (response?.data?.status === true) {
        console.log(response?.data?.data, "Response data is here");
        setdailyRateData({
          totalTodayUsedPrice: response?.data?.data?.totalTodayUsedPrice || "0",
          totalTodayUsedData: response?.data?.data?.totalTodayUsedData || "0", //
          dataRemaining: response?.data?.data?.dataRemaining || "0", //
          discountPrice: response?.data?.data?.discountPrice || "0",
          lastPayment: response?.data?.data?.lastPayment || "0",
          noOfVideos: response?.data?.data?.noOfVideos || "0", //
          noOfImages: response?.data?.data?.noOfImages || "0", //
          ownerName: response?.data?.data?.ownerName || "0", //
          noOfAudio: response?.data?.data?.noOfAudio || "0", //
          imageSize: response?.data?.data?.imageSize || "0", //
          videoSize: response?.data?.data?.videoSize || "0", //
          audioSize: response?.data?.data?.audioSize || "0", //
          dataused: response?.data?.data?.dataused || "0", //
          docSize: response?.data?.data?.docSize || "0", //
          noOfDoc: response?.data?.data?.noOfDoc || "0", //
          ownerId: response?.data?.data?.ownerId || "0", //
          discountPrice: subscriptionCurrentPlanData?.discountPrice,
        });
      } else {
        console.log("Error");
      }
    });
  };

  const postDailyRate = async () => {
    let obj = dailyRateData;
    obj.todayDate = moment().format("DD/MM/YYYY");
    folderService
      ?.updateDailyRate(obj)
      .then((resp) => {
        console.log(resp, "update daily rate");
      })
      .catch((err) => {
        console.log(err, "update daily rate");
      });
  };

  // GET FOLDER LIST API
  const fetchData = async (ownerId) => {
    setIsLoadingFolderList(true);
    folderService.getAlbumList(ownerId).then((response) => {
      if (response?.data?.status === true) {
        setFolderList(response?.data?.data || []);
        setIsLoadingFolderList(false);
      } else {
        console.log("Error");
        setIsLoadingFolderList(false);
      }
    });
  };
  const step1FormData = useSelector(
    (state) => state.recordingForm.recordVdoStep
  );
  const setFieldValue = useSelector(
    (state) => state.recordingForm.setFieldValue
  );
  const recordedData = useSelector(
    (state) => state.recordingForm.recordingData
  );
  // ---------------------------------------
  // FOR THE PRE FILLED FORM PART
  useEffect(() => {
    if (step1FormData && setFieldValue) {
      formik.setFieldValue("folderName", setFieldValue.selectedFolder);
      formik.setFieldValue("title", step1FormData.title);
      formik.setFieldValue("description", step1FormData.description);
      formik.setFieldValue("file", recordedData?.vdoUrl);

      setSelectedFolder(step1FormData.selectedFolder);
      setTestView(true);
      setMediaType(null);
    }
  }, [step1FormData]);
  const videoNameSlug = selectedFileName?.replaceAll(" ", "-");
  // SUBMIT
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      scheduleTime: "",
      file: "",
      folderName: "",
      fileSize: "",
      fileLength: "",
    },
    validationSchema: (values) => {
      if (!manageRecordVdoValidation) {
        // UPLOAD
        return Yup.object().shape({
          title: Yup.string().required("Title is required"),
          description: Yup.string(),
          folderName: Yup.string().required("Folder is required"),
          file: Yup.mixed().required("Media file is required"),
        });
      } else {
        // RECORD
        return Yup.object().shape({
          title: Yup.string().required("Title is required"),
          description: Yup.string(),
          folderName: Yup.string().required("Folder is required"),
        });
      }
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setIsActiveLoading(true);
      try {
        setSubmitting(true);
        setIsLoadingVdoUpload(true);
        if (mediaType === "image" || mediaType === "document") {
          // Image upload logic
          const ownerFolderData = {
            ownerId: loggedInOwnerData?._id,
            folderId: selectedFolder?.value?._id,
            folderPath: selectedFolder?.value?.path,
            folderName: selectedFolder?.value?.folderName,
            title: values.title,
            description: values.description,
            scheduleTime: selectedDateTime ? selectedDateTime : null,
            fileName: selectedFileName || selectedFile?.name,
            file: base64String,
            fileSize: selectedFile.size,
          };

          const response = await mediaServices.uploadOwnerFiles(
            ownerFolderData,
            user?.token
          );
          if (
            response?.data?.expired == true &&
            response?.message == "Your Current Plan is Expred !"
          ) {
            // toast.error("Your current plan is Expired!");
            Swal.fire({
              title: "Upgrade Plan",
              html: `
               <p><strong> Plan Expired</strong></p>
                <p>You need to upgrade plan to proceed.</p>
              `,
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/${role}/plans`);
              } else {
                handleClose();
              }
            });

            return;
          }
          if (response.status === true) {
            if (
              subscriptionCurrentPlanData?.selectedPlanType === "PayAsYouGo"
            ) {
              postDailyRate();
            }

            handleUpdateThumbNailUrl(response?.data);
            toast.success("Media is uploaded successfully", {});
            resetForm();
          }
        }
        if (mediaType === "video") {
          // Video upload logic
          const videoData = {
            ownerId: loggedInOwnerData?._id,
            folderId: selectedFolder?.value?._id,
            folderPath: selectedFolder?.value?.path,
            folderName: selectedFolder?.value?.folderName,
            title: values.title,
            description: values.description,
            scheduleTime: selectedDateTime ? selectedDateTime : null,
            fileName: selectedFileName,
            fileSize: selectedFile?.size,
            fileLength: videoLength,
          };

          planServices.getUniqueVal().then((resp) => {
          
            const encryptedData = resp?.data?.data;   
            const secretKey = atob(encryptedData?.secret)
            const secretAccessKey = decrypt(encryptedData?.secretAccessKey, secretKey);
            const accessKeyId = decrypt(encryptedData?.accessKeyId, secretKey);
             
            AWS.config.update({
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              region: 'us-east-1',
            });
             
            const s3 = new AWS.S3();   
          const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
          const key = `${selectedFolder?.value?.path}/${videoNameSlug}`;
          const params = {
            Bucket: bucketName,
            Key: key,
            Body: selectedFile,
            ContentType: selectedFile.type,
          };
          const controller = new AbortController();
          setUploadController(controller);

          s3.upload(
            { ...params, Signal: controller.signal },
            setManageS3Loader(true),

            async (err, data) => {
              setIsActiveLoading(true);
              setManageS3Loader(true);

              if (controller.signal.aborted) {
                console.log("Upload aborted");
                setIsLoadingVdoUpload(false);
                setIsActiveLoading(false);
                setManageS3Loader(false);

                return;
              }
              if (err) {
                console.error("Error uploading file to S3:", err);
                toast.error("Unable to upload file");
                setIsLoadingVdoUpload(false);
                setIsActiveLoading(false);
                setManageS3Loader(false);

                return;
              }
              console.log("File uploaded successfully:", data.Location);
              setIsLoadingVdoUpload(false);
              setManageS3Loader(false);

              try {
                const response = await videoService.uploadVideo(
                  videoData,
                  user?.token
                );
                if (response?.data?.status === true) {
                  if (
                    subscriptionCurrentPlanData?.selectedPlanType ===
                    "PayAsYouGo"
                  ) {
                    postDailyRate();
                  }
                  toast.success("Video has been uploaded successfully");

                  handleClose();
                  if (pathname == "/owner/memories") {
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                  navigate("/owner/memories");
                  setSelectedFolder(null);
                  setFolderSelected(false);
                  setSelectedFile(null);
                  setMediaType(null);
                  resetForm();
                  setIsActiveLoading(false);
                }
              } catch (error) {
                console.log("Network ERROR>>", error);
                console.log(
                  error.response.data?.message,
                  "error.response.data?.message"
                );
                setIsLoadingVdoUpload(false);
                setIsActiveLoading(false);
                if (error.response.data.statusCode == 500) {
                  toast.success(`File Uploaded Successfully..`);
                  setIsActiveLoading(false);
                  setSelectedFolder(null);
                  setFolderSelected(false);
                  setSelectedFile(null);
                  setMediaType(null);
                  resetForm();
                  handleClose();
                  if (pathname == "/owner/memories") {
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                  navigate("/owner/memories");
                } else {
                  toast.error("Unable to upload video");
                  setIsLoadingVdoUpload(false);
                  setIsActiveLoading(false);
                }
              } finally {
                setIsLoadingVdoUpload(false);
                setIsActiveLoading(false);
              }
            }
          ).on("httpUploadProgress", (progress) => {
            const uploadedBytes = progress.loaded;
            const totalBytes = progress.total;
            const percentage = Math.round((uploadedBytes / totalBytes) * 100);
            setUploadProgress(percentage);
          });
          
          }).catch((err) => {
          console.log(err , 'error is here 3');
          
          })

         
        }

        // RECORDING
        if (manageRecordVdoValidation) {
          await formik.validateForm(values);
          handleClose();
          setShowRecordingModel(true);
          handleVdoRecordStepOneData({ ...values, selectedFolder });
        }
      } catch (error) {
        console.log("444444444444444444444444444444444");
        console.log("Error:", error);
        toast.error("Error occurred while submitting the form");
        setIsActiveLoading(false);
      } finally {
        setSubmitting(false);
        setIsActiveLoading(false);
      }
    },
  });

  const updateDailyRateData = (
    fileTypeKey,
    fileSizeKey,
    fileCountKey,
    fileSize,
    remainingSpace
  ) => {
    console.log(fileSize, "file size");

    // Given values
    const pricePerGB = subscriptionCurrentPlanData?.discountPrice; // price in paisa

    const bytesIn1GB = 1073741824; // Number of bytes in 1 GB

    // Data used in bytes
    const dataUsedInBytes = fileSize;

    // Calculate the number of GB used
    const dataUsedInGB = dataUsedInBytes / bytesIn1GB;

    // Calculate the total cost
    const totalCost = dataUsedInGB * pricePerGB;

    setdailyRateData((prevState) => ({
      ...prevState,
      totalTodayUsedPrice: parseInt(prevState?.totalTodayUsedPrice) + totalCost,
      [fileCountKey]: (parseInt(prevState[fileCountKey]) || 0) + 1,
      [fileSizeKey]: (parseInt(prevState[fileSizeKey]) || 0) + fileSize,
      dataused: (parseInt(prevState.dataused) || 0) + fileSize,
      dataRemaining: (parseInt(remainingSpace) || 0) - fileSize,
      totalTodayUsedData:
        (parseInt(prevState.totalTodayUsedData) || 0) + fileSize,
      lastPayment: moment(subscriptionCurrentPlanData?.startDate).format(
        "DD/MM/YYYY"
      ),
      ownerId: subscriptionCurrentPlanData?.ownerId,
      ownerName: subscriptionCurrentPlanData?.ownerName,
    }));
  };

  const handleFileInputChange = (event) => {
    const fileSize = event.target.files[0].size / (1024 * 1024);
    const remainingSpace =
      subscriptionCurrentPlanData?.remainingSpace / (1024 * 1024);
    const selectedPlanType = subscriptionCurrentPlanData?.selectedPlanType;

    if (event.target.files[0]?.type.includes("image")) {
      updateDailyRateData(
        "image",
        "imageSize",
        "noOfImages",
        event.target.files[0].size,
        subscriptionCurrentPlanData?.remainingSpace
      );
    } else if (event.target.files[0]?.type.includes("video")) {
      updateDailyRateData(
        "video",
        "videoSize",
        "noOfVideos",
        event.target.files[0].size,
        subscriptionCurrentPlanData?.remainingSpace
      );
    } else if (event.target.files[0]?.type.includes("application")) {
      updateDailyRateData(
        "doc",
        "docSize",
        "noOfDoc",
        event.target.files[0].size,
        subscriptionCurrentPlanData?.remainingSpace
      );
    } else if (event.target.files[0]?.type.includes("audio")) {
      updateDailyRateData(
        "audio",
        "audioSize",
        "noOfAudio",
        event.target.files[0].size,
        subscriptionCurrentPlanData?.remainingSpace
      );
    }

    // Only handle the "PayAsYouGo" plan with the SweetAlert
    if (selectedPlanType === "PayAsYouGo" && fileSize > remainingSpace) {
      Swal.fire({
        title: "Refill Top Up",
        html: `
          <p><strong>${selectedPlanType} Plan</strong></p>
          <p>Your remaining space is: <strong>${remainingSpace.toFixed(
            2
          )} MB</strong></p>
          <p>Your file size is: <strong>${fileSize.toFixed(2)} MB</strong></p>
          <p>You need to upgrade or avail top-up to proceed, or try using a smaller file.</p>
        `,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Top-Up Now",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/${role}/plans`);
        } else {
          handleClose();
        }
      });

      // Return early since this is only for "PayAsYouGo" and we don't want to proceed further.
      return;
    }
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const fileName = file.name;
      const fileNameWithoutSpace = fileName.replace(/[^a-zA-Z0-9.]+/g, "");
      setSelectedFileName(fileNameWithoutSpace ? fileNameWithoutSpace : null);

      const allowedImageTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/svg+xml",
      ];

      const allowedVideoTypes = [
        "video/mp4",
        "video/avi",
        "video/mkv",
        "video/quicktime",
        "video/x-ms-wmv",
        "video/x-flv",
        "video/webm",
        "video/x-matroska",
      ];

      const allowedDocumentExtensions = [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "txt",
      ];

      // Handle different file types (image, video, document)
      if (allowedImageTypes.includes(file.type)) {
        if (
          selectedPlanType === "Free" &&
          subscriptionCurrentPlanData?.validityType == "Month"
        ) {
          setMediaType("image");
          // toast.error(
          //   "File upload is unavailable on current plan. Upgrade for access or contact support."
          // );
        } else {
          setMediaType("image");
        }
      } else if (allowedVideoTypes.includes(file.type)) {
        setMediaType("video");
      } else if (
        allowedDocumentExtensions.includes(getFileExtension(file.name))
      ) {
        if (
          selectedPlanType === "Free" &&
          subscriptionCurrentPlanData?.validityType == "Month"
        ) {
          setMediaType("document");
          toast.error(
            "File upload is unavailable on current plan. Upgrade for access or contact support."
          );
        } else {
          setMediaType("document");
        }
      } else {
        toast.error(getErrorMessage(file.type));
        return;
      }

      // Convert file to base64 and set it
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64String(base64String);
      };
      reader.readAsDataURL(file);

      formik.setFieldValue("file", file);
      setSelectedFile(file);
    } else {
      console.error("No file selected for upload.");
    }
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop().toLowerCase();
  };

  const getErrorMessage = (fileType) => {
    if (fileType.startsWith("image/")) {
      return "Invalid file type. Please select a JPG, JPEG, PNG, GIF, WebP, or SVG file.";
    } else if (fileType.startsWith("video/")) {
      return "Invalid file type. Please select an MP4, AVI, MKV, MOV, WMV, FLV, or WebM file.";
    } else {
      return "Unsupported file type.";
    }
  };

  // FOR UPDATNG THE IMAGE URL ONCE THE IMG UPLOAD FUNC RUNS
  const handleUpdateThumbNailUrl = (folderData) => {
    const ownerFolderUrlData = {
      fileName: folderData?.fileName,
      _id: folderData?._id,
      path: folderData?.folderPath,
    };
    folderService
      .updateFileUrl(ownerFolderUrlData)
      .then((response) => {
        if (response.status == true) {
          console.log("Url updated");
          setSelectedFolder(null);
          setFolderSelected(false);
          setSelectedFile(null);
          setMediaType(null);
          setBase64String("");
          handleClose();
          if (pathname == "/owner/memories") {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          navigate("/owner/memories");
        }
      })
      .catch((error) => {
        console.log("Network ERROR", error);
      });
  };

  // FOR STORING THE VALUES INTO REDUX FOR THE PRE-FILLED FORM PART
  const handleVdoRecordStepOneData = (data) => {
    dispatch(setRecordVdoStep1Data(data));
  };

  let metaDatafileName = `${`recorded_vdo_${Date.now()}.mp4`}`;
  let s3Key = `${step1FormData?.selectedFolder?.value?.path}/${metaDatafileName}`;
  const handleRecordUpload = async () => {
    setManageS3Loader(true);
    setIsLoadingVdoUpload(true);
    setIsActiveLoading(true);

    // Check if recordedData exists
    if (!recordedData) {
      console.error("Recorded data is missing.");
      setIsLoadingVdoUpload(false);
      setIsActiveLoading(false);
      setManageS3Loader(false);
      return;
    }

    const resolution = await getVideoResolution(recordedData);
    const fileSize = recordedData?.blobData?.size / (1024 * 1024);
    const remainingSpace =
      subscriptionCurrentPlanData?.remainingSpace / (1024 * 1024);
    const selectedPlanType = subscriptionCurrentPlanData?.selectedPlanType;

    // Check if the plan is "PayAsYouGo" and if the file size exceeds the remaining space
    if (
      subscriptionCurrentPlanData?.selectedPlanType === "PayAsYouGo" &&
      fileSize > remainingSpace
    ) {
      Swal.fire({
        title: "Refill Top Up",
        html: `
         <p><strong>${selectedPlanType} Plan</strong></p>
          <p>Your remaining space is: <strong>${remainingSpace.toFixed(
            2
          )} MB</strong></p>
          <p>Your file size is: <strong>${fileSize.toFixed(2)} MB</strong></p>
          <p>You need to upgrade or avail top-up to proceed, or try using a smaller file.</p>
        `,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/${role}/plans`);
        } else {
          handleClose();
        }
      });

      // Stop further execution since space is not enough
      setIsLoadingVdoUpload(false);
      setIsActiveLoading(false);
      setManageS3Loader(false);
      return;
    }

    // If there is enough space, proceed with the upload
    const videoData = {
      ownerId: loggedInOwnerData?._id,
      folderId: step1FormData?.selectedFolder?.value?._id,
      folderPath: step1FormData?.selectedFolder?.value?.path,
      folderName: step1FormData?.selectedFolder?.value?.folderName,
      title: step1FormData.title,
      description: step1FormData.description,
      scheduleTime: selectedDateTime ? selectedDateTime : null,
      fileName: metaDatafileName,
      fileSize: recordedData?.blobData?.size,
      fileLength: resolution.duration,
    };

    planServices.getUniqueVal().then((resp) => {
    
      const encryptedData = resp?.data?.data;   
      const secretKey = atob(encryptedData?.secret)
      const secretAccessKey = decrypt(encryptedData?.secretAccessKey, secretKey);
      const accessKeyId = decrypt(encryptedData?.accessKeyId, secretKey);
       
      AWS.config.update({
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: 'us-east-1',
      });
       
      const s3 = new AWS.S3();   
    const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
    const key = s3Key;
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: recordedData?.blobData,
      ContentType: `video/mp4`,
    };

    const controller = new AbortController();
    setUploadController(controller);

    s3.upload({ ...params, Signal: controller.signal }, async (err, data) => {
      if (controller.signal.aborted) {
        console.log("Upload aborted");
        setIsLoadingVdoUpload(false);
        setIsActiveLoading(false);
        setManageS3Loader(false);
        return;
      }
      if (err) {
        console.error("Error uploading file to S3:", err);
        toast.error("Unable to upload recorded video");
        setIsLoadingVdoUpload(false);
        setIsActiveLoading(false);
        setManageS3Loader(false);
        return;
      }

      //   console.log("Recorded File uploaded successfully:", data.Location);
      setIsLoadingVdoUpload(false);
      setIsActiveLoading(false);
      setManageS3Loader(false);

      try {
        const response = await videoService.uploadVideo(videoData, user?.token);
        if (response?.data?.status === true) {
          toast.success("Recording has been uploaded successfully");

          handleClose();
          if (pathname === "/owner/memories") {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          navigate("/owner/memories");
          setMediaType(null);
          setIsActiveLoading(false);
          dispatch(emptyRecordedData());
        }
      } catch (error) {
        console.log("Network ERROR", error);
        toast.error("Unable to upload recording");
        setIsActiveLoading(false);
      }
    }).on("httpUploadProgress", (progress) => {
      const uploadedBytes = progress.loaded;
      const totalBytes = progress.total;
      const percentage = Math.round((uploadedBytes / totalBytes) * 100);
      setUploadProgress(percentage);
    });
      }).catch((err) => {
        toast.error("Smething went wrong");
      })


  };

  // RESOLUTION FOR RECORDINF
  const getVideoResolution = async (recordedData) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = async () => {
        window.URL.revokeObjectURL(video.src);
        const resolution = {
          width: video.videoWidth,
          height: video.videoHeight,
        };
        try {
          const duration = await getBlobDuration(recordedData?.vdoUrl);
          resolution.duration = duration;
          resolve(resolution);
        } catch (error) {
          reject(error);
        }
      };
      video.onerror = (e) => {
        reject(e);
      };
      const objectURL = URL.createObjectURL(recordedData?.blobData);
      video.src = objectURL;
    });
  };

  // FOR ABORTING THE S3 UPLOAD ON CANCEL BUTTON
  const abortUpload = () => {
    if (uploadController) {
      uploadController.abort();
      setUploadController(null);
      setIsActiveLoading(false);
    }
  };

  const [selectedDateTime, setSelectedDateTime] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    const formattedDateTime = value + ":00.000Z";
    setScheduleSharingError("");

    const selectedDate = new Date(formattedDateTime);
    const currentDate = new Date();

    if (selectedDate < currentDate) {
      toast.error("Please select a future date and time.");
    } else {
      setSelectedDateTime(formattedDateTime);
    }
  };

  const handleSubmit = (event) => {
    setIsActiveLoading(true);
    event.preventDefault();
    formik.setFieldValue("scheduleTime", selectedDateTime); //
    if (selectedDateTime) {
      toast.success(
        "Time will be scheduled for: " +
          moment(selectedDateTime).format("Do MMM'YY h:mm A")
      );
      setScheduleSharingError("");
    } else {
      setScheduleSharingError("Schedule a time first");
    }

    setIsActiveLoading(false);
  };

  return (
    <>
      {
        <div className="form-container">
          <Row>
            <Col xl={12} lg={12} xs={12} className="text-center">
              <h3 className="modal-heading">Save New Memory</h3>
            </Col>
          </Row>
          {!isLoadingFolderList ? (
            <Form onSubmit={formik.handleSubmit}>
              {folderList?.length > 0 ? (
                <>
                  {isActiveLoading && <Loader />}
                  <Row className="mb-3">
                    <Col xl={8} lg={8} md={6} xs={12}>
                      <InputGroup className="gap-2 login-input-section justify-content-center ">
                        <Form.Control
                          className="w-100 min-height-60"
                          placeholder="Memory ..."
                          type="text"
                          name="title"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        )}
                      </InputGroup>
                    </Col>
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      xs={12}
                      className="select-folder-test"
                    >
                      <Select
                        classNames={``}
                        value={selectedFolder}
                        onChange={(selectedOption) => {
                          setSelectedFolder(selectedOption);
                          setFolderSelected(true);
                          formik.setFieldValue(
                            "folderName",
                            selectedOption?.value?.folderName
                          );
                        }}
                        options={folderList?.map((folder) => ({
                          label: folder.folderName,
                          value: folder,
                        }))}
                        defaultValue={selectedFolder || []}
                        placeholder="Add to Existing Album"
                        className="invite_access-dropdown"
                        isSearchable={false}
                      />
                      {formik.touched.folderName && formik.errors.folderName ? (
                        <div className="text-danger">
                          {formik.errors.folderName}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xl={12} lg={12} md={12} xs={12}>
                      <InputGroup className=" login-input-section justify-content-center">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="w-100 min-height-60"
                          placeholder="Use this space to capture the essence of the memory.Share any details that make this moment special or meaningful to you."
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="text-danger">
                              {formik.errors.description}
                            </div>
                          )}
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    {
                      <Col xl={12} lg={12} md={12} xs={12}>
                        {
                          <div className="video-settings-upload">
                            {mediaType == null && recordedData?.length == 0 && (
                              <>
                                <input
                                  type="file"
                                  id="video-settings-upload"
                                  onChange={handleFileInputChange}
                                />
                                <label>
                                  <i className="ri-upload-line"></i> Drag and
                                  Drop here <br /> OR <br />{" "}
                                  <a href="#" className="browse-file">
                                    Browse files
                                  </a>
                                </label>
                                {formik.touched.file && formik.errors.file && (
                                  <div className="text-danger">
                                    {formik.errors.file}
                                  </div>
                                )}
                              </>
                            )}

                            {base64String && mediaType == "image" && (
                              <div className="text-center">
                                <img
                                  src={base64String}
                                  alt="Uploaded"
                                  className="uploaded-image img-fluid"
                                  height={`500`}
                                  width={`400`}
                                />
                              </div>
                            )}
                            {!manageS3Loader && mediaType === "video" && (
                              <div className="text-center">
                                <img
                                  src={`/video-placeholder.png`}
                                  alt="Uploaded"
                                  height={`500`}
                                  width={`400`}
                                  className="img-fluid"
                                />
                              </div>
                            )}

                            {manageS3Loader && mediaType === "video" && (
                              <>
                                <ProgressBar
                                  now={uploadProgress}
                                  label={`${uploadProgress}%`}
                                />
                                {uploadProgress === 100 && (
                                  <div className="text-center">
                                    <img
                                      src={`/vdo-uploaded-successfully.png`}
                                      alt="Uploaded"
                                      height={`500`}
                                      width={`400`}
                                      className="img-fluid"
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            {mediaType == "document" && (
                              <div className="text-center">
                                <img
                                  src={`/doc-placeholder.png`}
                                  alt="Uploaded"
                                  className="uploaded-image img-fluid"
                                  height={`500`}
                                  width={`400`}
                                />
                              </div>
                            )}
                            {recordedData.vdoUrl && !manageS3Loader && (
                              <div className="d-flex justify-content-center">
                                <video src={recordedData.vdoUrl} controls />
                              </div>
                            )}

                            {recordedData.vdoUrl && manageS3Loader && (
                              <>
                                <ProgressBar
                                  now={uploadProgress}
                                  label={`${uploadProgress}%`}
                                />
                                {uploadProgress === 100 && (
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={`/vdo-uploaded-successfully.png`}
                                      alt="Uploaded"
                                      height={`500`}
                                      width={`400`}
                                      className="img-fluid"
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        }
                      </Col>
                    }
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      xs={12}
                      className="text-end"
                      onClick={() => {
                        setManageRecordVdoValidation(true);
                        setMediaType("record");
                      }}
                    >
                      {!recordedData.vdoUrl && (
                        <Button type="submit" className="btn-transparent">
                          {" "}
                          Record directly from your device/PC{" "}
                        </Button>
                      )}
                    </Col>

                    <Col className="py-3">
                      <div className="py-3">
                        <input
                          type="datetime-local"
                          id="birthdaytime"
                          name="birthdaytime"
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (!selectedDateTime) e.preventDefault();
                          }}
                          onPaste={(e) => {
                            if (!selectedDateTime) e.preventDefault();
                          }}
                          min={new Date().toISOString().slice(0, 16)}
                        />
                        <Button onClick={handleSubmit}>OK</Button>
                      </div>
                      {scheduleSharingError != "" && (
                        <p className="text-danger">{scheduleSharingError}</p>
                      )}
                    </Col>
                  </Row>
                  <div className="border-0 text-center pt-5">
                    <Button
                      variant="primary"
                      className="create-btn mb-3"
                      type="submit"
                      onClick={() => {
                        setFieldValue && handleRecordUpload();
                      }}
                    >
                      Create Memory
                    </Button>
                    <span
                      className="w-100 text-muted d-flex justify-content-center align-items-center"
                      onClick={() => {
                        handleClose();
                        abortUpload();
                        dispatch(emptyRecordedData());
                        setManageRecordVdoValidation(false);
                      }}
                    >
                      <b>Cancel</b>
                    </span>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <p className="my-2">
                    Please add a folder in order to proceed
                  </p>
                  <Button
                    className="mt-3"
                    onClick={(e) => {
                      navigate("/owner/albums");
                      handleClose();
                    }}
                  >
                    Go to Album
                  </Button>
                </div>
              )}
            </Form>
          ) : (
            <Skeleton height={100} />
          )}
        </div>
      }
    </>
  );
};

export default FolderModal;
